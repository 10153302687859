import React, { useCallback } from "react";
import { Link, navigate } from "gatsby";
import { Formik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Heading from "$ui/heading";
import Input from "$ui/input";
import Button from "$ui/button";
import Container from "$ui/container";
import PincodeField from "$ui/pincodeField";

import { useSignup } from "$hooks/auth";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const signUpschema = Yup.object().shape({
  fullName: Yup.string().trim().required("Please provide your name"),

  phone: Yup.string()
    .matches(phoneRegExp, "Invalid phone number")
    .required("Please provide your phone number"),

  email: Yup.string()
    .email("Invalid email")
    .required("Please provide your email address"),

  dateOfBirth: Yup.date()
    .min("1946-12-31T18:30:00.000Z", "Please provide correct date of birth")
    .max(new Date().toISOString(), "Please provide correct date of birth")
    .required("Please provide your date of birth"),

  password: Yup.string()
    .trim()
    .min(8, "Password is too short")
    .required("Please provide your password"),

  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "The two passwords do not match")
    .required("Please confirm your password"),

  pincode: Yup.string()
    .matches(/^[0-9]+$/, "Pincode can contain only digits")
    .min(6, "Pincode must be of exactly 6 digits")
    .max(6, "Pincode must be of exactly 6 digits")
    .required("Please provide your pincode"),

  state: Yup.string().required("Please provide the state in which you live"),

  city: Yup.string().required("Please provide the city in which you live"),

  address: Yup.string()
    .trim()
    .required("Please provide your current residential address"),
});

const initialValues = {
  fullName: "",
  phone: "",
  email: "",
  password: "",
  confirmPassword: "",
  dateOfBirth: "",
  pincode: "",
  state: "",
  city: "",
  address: "",
};

const SignupPage = () => {
  const { mutateAsync } = useSignup();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await mutateAsync(values);
        toast.success(
          "Signed up successfully! Please confirm your email before logging in"
        );
        navigate("/auth/login");
      } catch (err) {
        toast.error(err.response?.data.message || "Network Error");
      }
    },
    [mutateAsync]
  );

  return (
    <Container display="flex" className="bg-primary phone:px-4">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={signUpschema}
        initialValues={initialValues}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            className="w-[40rem] py-10 px-10 bg-gray-100 space-y-5 rounded-xl shadow-2xl phone:w-full"
          >
            <Heading variant="tertiary">signup now</Heading>

            <div className="grid grid-cols-2 gap-y-5 gap-x-10 tab-port:grid-cols-none">
              <Input
                name="fullName"
                placeholder="Full name"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.fullName && formik.errors.fullName}
              />

              <Input
                name="email"
                placeholder="Email address"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && formik.errors.email}
              />

              <Input
                name="phone"
                type="tel"
                placeholder="Phone number"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && formik.errors.phone}
              />

              <Input
                name="password"
                type="password"
                placeholder="Password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && formik.errors.password}
              />

              <Input
                name="confirmPassword"
                type="password"
                placeholder="confirm Password"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                }
              />

              <Input
                type="date"
                name="dateOfBirth"
                placeholder="date of birth"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dateOfBirth}
                error={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
              />

              <PincodeField
                name="pincode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pincode}
                error={formik.touched.pincode && formik.errors.pincode}
                setFieldValue={formik.setFieldValue}
              />

              <Input
                name="state"
                placeholder="state"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                error={formik.touched.state && formik.errors.state}
                disabled
              />

              <Input
                name="city"
                placeholder="city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                error={formik.touched.city && formik.errors.city}
                disabled
              />

              <Input
                name="address"
                placeholder="street address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={formik.touched.address && formik.errors.address}
              />

              <Button
                variant="filled"
                type="submit"
                className="col-span-full justify-self-center"
                isLoading={formik.isSubmitting}
              >
                Sign up
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default SignupPage;
